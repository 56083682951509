<template>
  <div v-show="loading === true" class="loader">
    <a-spin tip="Loading..." size="large"></a-spin>
  </div>
  <div class="fp-select">
    <a-select
      :default-value="floorPlanId"
      class="ant-select"
      :value="floorPlanId"
      @change="handleFpChange"
      :options="fpSelectValues"
    ></a-select>
  </div>
  <div id="map" style="height: 100vh; width: 100%"></div>
</template>

<script lang="ts" src="./FloorPlan.component.ts"></script>

<style>
.loader {
  height: 100vh;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.entrance {
  width: 26px;
  height: 26px;
  background-image: url("../../src/assets/entrance.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 999;
}
.exit {
  width: 26px;
  height: 26px;
  background-image: url("../../src/assets/exit.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 999;
}
.fp-select {
  position: absolute;
  margin: 1rem 1rem 0 auto;
  right: 0;
  z-index: 10;
}
.ant-select {
  width: 280px;
}
@media screen and (max-width: 450px) {
  .ant-select {
    width: 180px;
  }
}
</style>
