
import { Options, Vue } from "vue-class-component";
import FloorPlan from "./components/FloorPlan.vue";
import LeftPanel from "./components/LeftPanel.vue";
import RightPanel from "./components/RightPanel.vue";

@Options({
  components: {
    FloorPlan,
    LeftPanel,
    RightPanel,
  },
})
export default class App extends Vue {}
