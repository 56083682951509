import store from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  watch: {
    totalTripsTime: function() {
      this.tripsTime = store.state.tripsTime;
    },
    totalDistance: function() {
      this.distance = store.state.totalDistance;
    },
    totalItemDelay: function() {
      this.itemDelay = store.getters.getItemDelay;
    },
    sortedItemsList: function() {
      this.products = store.getters.getItemsList.slice(0, 4);
    }
  }
})
export default class LeftPanel extends Vue {
  public panelVisible = true;
  public totalItems = store.getters.getItemsList;
  public products = store.getters.getItemsList.slice(0, 4);
  public travelSpeed = store.getters.getTravelSpeed;
  public itemDelay = store.getters.getItemDelay;
  public tripsTime = store.state.tripsTime;
  public distance = store.state.totalDistance;

  public get totalTravelSpeed(): number {
    return this.travelSpeed = store.getters.getTravelSpeed;
  }

  public get totalItemDelay(): number {
    return this.itemDelay = store.getters.getItemDelay
  }

  public get totalTripsTime() {
    return this.tripsTime = store.state.tripsTime
  }

  public get totalDistance() {
    return this.distance = store.state.totalDistance;
  }

  public get sortedItemsList() {
    return this.totalItems = store.getters.getItemsList
  }

  public sortItemsList() {
    const shuffleArray = this.totalItems.sort(() => Math.random() - 0.5);
    return store.dispatch('setItemsList', shuffleArray).then(() => {
      this.products = store.getters.getItemsList.slice(0, 4);
    })
  }

  public onChangeSpeed(value: number) {
    return store.dispatch('setTravelSpeed', value)
  }

  public onChangeDelay(value: number) {
    return store.dispatch('setItemDelay', value)
  }

  public showPanel(): boolean {
    return this.panelVisible = true;
  }

  public onPanelClose(): boolean {
    return this.panelVisible = false;
  }
}