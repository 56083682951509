import store from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  watch: {
    selectedAsset: function() {
      this.selectedAssetData = store.getters.getSelectedAsset;
    },
    openDrawer: function() {
      this.visible = store.state.visibleLeftDrawer;
    }
  }
})
export default class RightPanel extends Vue {
  public visible: boolean = store.state.visibleLeftDrawer;
  public selectedAssetData: any = store.getters.getSelectedAsset;

  public get selectedAsset() {
    return this.selectedAssetData = store.getters.getSelectedAsset
  }

  public get openDrawer() {
    return this.visible = store.state.visibleLeftDrawer
  }

  public onClose() {
    store.dispatch("setLeftDrawerVisible", false);
    this.selectedAssetData = {}
  }

  public saveItem(value) {
    const position = [this.selectedAssetData.location.lng, this.selectedAssetData.location.lat]
    const payload = { name: value, position, floorPlanSelected: this.selectedAssetData.fpSelected };
    store.dispatch("saveItemsLocation", payload);
  }
}