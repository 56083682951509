/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Options, Vue } from "vue-class-component";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as turf from "@turf/turf";
import svgToImage from "@/modules/svg-to-png.module";
import { IfloorPlanImage } from "@/model/floor-plan-image.model";
import PF from "pathfinding";
import store from "@/store";
import moment from "moment";
import "moment-duration-format";
import { floorPlanSource } from "../floorplans";

@Options({
  watch: {
    itemsSelected: function() {
      this.itemsList = store.getters.getItemsList.slice(0, 4);
      this.paths = [];
      this.findPath(this.map, this.grid);
    },
    travelSpeed: function() {
      this.speed = store.getters.getTravelSpeed;
      this.calculateTripTime();
    },
    itemDelay: function() {
      this.delay = store.getters.getItemDelay;
      this.calculateTripTime();
    },
    allItemsList: function() {
      this.allItems = store.getters.getItemsList;
    },
  },
})
export default class FloorPlan extends Vue {
  public floorPlanId = "72e1e909-2226-4199-b814-1fc3d2760530";
  public floorPlan: any = {};
  public floorPlanAssets: any = {};
  public floorPlanImage: IfloorPlanImage = {};
  public entranceDoor: any = [];
  public exitDoor: any = [];
  public NE: any = {};
  public SW: any = {};
  public center: any = [];
  public zoom = 20;
  public itemsList = store.getters.getItemsList.slice(0, 4);
  public allItems = store.getters.getItemsList;
  public coordinates = [];
  public mapHeight = null;
  public mapWidth = null;
  public itemsMarker: any = turf.featureCollection([]);
  public matrix = [];
  public paths = [];
  public map: any = {};
  public grid = {};
  public distance = 0;
  public speed = store.getters.getTravelSpeed;
  public delay = store.getters.getItemDelay;
  public fpSelectValues = {};
  public loading = true;
  $notification: any;

  public get itemsSelected() {
    return (this.itemsList = store.getters.getItemsList.slice(0, 4));
  }

  public get allItemsList() {
    return (this.allItems = store.getters.getItemsList);
  }

  public get travelSpeed() {
    return (this.speed = store.getters.getTravelSpeed);
  }

  public get itemDelay() {
    return (this.delay = store.getters.getItemDelay);
  }

  beforeCreate() {
    store.dispatch("loadLocalStorage", this.floorPlanId);
  }

  created() {
    this.retrieveFloorPlanData();
    this.retrieveFloorPlanImage();
    this.retrieveFloorPlanAssetsData();
    this.fpSelectValues = floorPlanSource.map((it) => {
      return { value: it.id, label: it.name };
    });
  }

  beforeUpdate() {
    store.dispatch("loadLocalStorage", this.floorPlanId);
  }

  // Function triggered when changing floor plan from select
  public handleFpChange(value: string) {
    this.loading = true;
    this.floorPlanId = value;
    this.map.remove();
    this.retrieveFloorPlanData();
    this.retrieveFloorPlanImage();
    this.retrieveFloorPlanAssetsData();
  }

  public retrieveFloorPlanData() {
    const floorPlanId = this.floorPlanId;
    const publishableToken = process.env.VUE_APP_ARCHILOGIC_PUBLISHABLE_TOKEN;
    fetch(
      `https://api.archilogic.com/v2/space?floorId=${floorPlanId}&geometry=true&pubtoken=${publishableToken}`,
      {method: "GET"}
    )
      .then((response) => response.json())
      .then((data) => {
        this.floorPlan = data;
        const fpConfig = floorPlanSource.find((it) => it.id === this.floorPlanId);
        this.entranceDoor = fpConfig.entranceDoor;
        this.exitDoor = fpConfig.exitDoor;
        this.NE = fpConfig.ne;
        this.SW = fpConfig.sw;
        this.center = fpConfig.center; 
        this.zoom = fpConfig.zoom;
      });
  }

  public retrieveFloorPlanAssetsData() {
    const floorPlanId = this.floorPlanId;
    const publishableToken = process.env.VUE_APP_ARCHILOGIC_PUBLISHABLE_TOKEN;
    fetch(
      `https://api.archilogic.com/v2/asset?floorId=${floorPlanId}&geometry=true&pubtoken=${publishableToken}`,
      {method: "GET"}
    )
      .then((response) => response.json())
      .then((data) => {this.floorPlanAssets = data;});
  }

  public retrieveFloorPlanImage() {
    const floorPlanId = this.floorPlanId;
    const publishableToken = process.env.VUE_APP_ARCHILOGIC_PUBLISHABLE_TOKEN;
    fetch(
      `https://api.archilogic.com/v2/floor/${floorPlanId}/2d-image?pubtoken=${publishableToken}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const { imageUrl, latLngBounds: latLng } = data;
        const coordinates: number[][] = [
          [latLng[0][1], latLng[0][0]],
          [latLng[1][1], latLng[0][0]],
          [latLng[1][1], latLng[1][0]],
          [latLng[0][1], latLng[1][0]],
        ];
        this.floorPlanImage = { imageUrl, coordinates };
        this.renderMapboxGl();
      });
  }

  public async renderMapboxGl() {
    mapboxgl.accessToken = process.env.VUE_APP_MAP_ACCESS_TOKEN;
    this.map = new mapboxgl.Map({
      container: "map",
      center: this.center,
      zoom: this.zoom,
    });

    const image = await svgToImage(this.floorPlanImage.imageUrl, 4096);
    const geoJson = this.floorPlan;
    const assetsPolygons: any = this.floorPlanAssets.features.map((asset) =>
      turf.polygon(asset.geometry.coordinates, {id: asset.id, name: asset.properties.name}));
    const assetsFeatureCollection: any = turf.featureCollection(assetsPolygons);

    this.map.addSource("floorPlan", {
      type: "image",
      url: image,
      coordinates: this.floorPlanImage.coordinates,
    });
    this.map.addLayer({
      id: "floorPlan",
      source: "floorPlan",
      type: "raster",
      paint: { "raster-opacity": 1 },
    });

    this.map.addSource("space", {
      type: "geojson",
      data: geoJson,
    });
    this.map.addLayer({
      id: "space-outline",
      type: "line",
      source: "space",
      paint: {
        "line-color": "#000",
        "line-width": 2,
      },
    });

    this.map.addSource("asset", {
      type: "geojson",
      data: assetsFeatureCollection,
    });
    this.map.addLayer({
      id: "asset-fill",
      type: "fill",
      source: "asset",
      paint: {
        "fill-color": {
          property: "name",
          type: "categorical",
          stops: [
            ["Beverages Refrigirator", "#b7defa"],
            ["Storage Rack", "#e7a469"],
            ["Storage Locker", "#738f92"],
            ["Big Office Plant", "#71c295"],
            ["Jamis Dakar Bike", "#a2babd"],
            ["Bondt Bank", "#705d4d"],
            ["MI T-1610", "#634d3b"],
            ["EUR Pallet 120cm x 80cm", "#b47c4e"],
          ],
          default: "#d5f2f2",
        },
        "fill-opacity": 0.4,
      },
    });
    this.map.addLayer({
      id: "asset-outline",
      type: "line",
      source: "asset",
      paint: {
        "line-color": "#000",
        "line-width": 0.1,
      },
    });

    this.map.addLayer({
      id: "asset-highlighted",
      type: "line",
      source: "asset",
      paint: {
        "line-color": "#4c6fcf",
        "line-width": 2.5,
      },
      filter: ["in", "id", ""],
    });

    const map = this.map;
    const floorPlanSelected = this.floorPlanId;

    this.map.on("click", (e) => {
      const selectedFeatures = map.queryRenderedFeatures(e.point, {
        layers: ["asset-fill"],
      });
      const markers = map.queryRenderedFeatures(e.point, {
        layers: ["products-circle"],
      });

      if (selectedFeatures.length > 0) {
        // Highlight asset on click
        const fips = selectedFeatures.map((feature) => feature.properties.id);
        map.setFilter("asset-highlighted", ["in", "id", ...fips]);
        // Set asset data for right panel
        const assetItems = markers.length > 0 ? [markers[0].properties] : [];
        const assetData = {
          id: selectedFeatures[0].properties.id,
          name: selectedFeatures[0].properties.name,
          location: e.lngLat,
          assetItems: assetItems,
          fpSelected: floorPlanSelected,
        };
        store.dispatch("saveSelectedAsset", assetData);
        store.dispatch("setLeftDrawerVisible", true);
      }
    });

    this.addMarkers(this.map);
  }

  public addMarkers(map) {
    const entranceMarker = document.createElement("div");
    entranceMarker.classList.add("entrance");
    new mapboxgl.Marker(entranceMarker).setLngLat(this.entranceDoor).addTo(map);

    const exitMarker = document.createElement("div");
    exitMarker.classList.add("exit");
    new mapboxgl.Marker(exitMarker).setLngLat(this.exitDoor).addTo(map);

    map.addSource("route", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    map.addLayer({
      id: "routeline-active",
      type: "line",
      source: "route",
      layout: {
        "line-cap": "round",
        "line-join": "bevel",
      },
      paint: {
        "line-color": "#208EC1",
        "line-width": 2.4,
      },
    });
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const url = require("@/assets/arrow.png");
    map.loadImage(url, (error: any, image: any) => {
      map.addImage("arrow", image);
      map.addLayer({
        id: "routearrows",
        type: "symbol",
        source: "route",
        layout: {
          "symbol-placement": "line",
          "text-size": 5,
          "symbol-spacing": 1,
          "text-keep-upright": false,
          "icon-allow-overlap": true,
          "icon-image": "arrow",
          "icon-size": 0.03,
          "visibility": "visible",
        },
      });
    });

    map.addSource("items", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    }),
      map.addLayer({
        id: "products-background",
        type: "circle",
        source: "items",
        paint: {
          "circle-radius": 18,
          "circle-color": "white",
          "circle-stroke-color": "#208EC1",
          "circle-stroke-width": 1.5,
        },
      });
    map.addLayer({
      id: "products-circle",
      type: "symbol",
      source: "items",
      layout: {
        "icon-allow-overlap": true,
        "icon-image": ["get", "name"],
        "icon-size": 0.05,
      },
    });

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
    map.on("mouseenter", "products-circle", (e) => {
      map.getCanvas().style.cursor = "pointer";
      const coordinates = e.features[0].geometry.coordinates;
      const description = e.features[0].properties.name;
      popup
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(map);
    });
    map.on("mouseleave", "products-circle", () => {
      map.getCanvas().style.cursor = "";
      popup.remove();
    });

    this.setPathFinderGrid(map);
    this.loading = false;
  }

  public setPathFinderGrid(map) {
    // Set map limit coordinates
    this.mapMeasures();

    // Initialize Path finder grid
    for (let i = 0; i < 99; i++) {
      this.matrix[i] = new Array(99).fill(0);
    }
    this.setNonWalkablePoints(this.floorPlanAssets);
    this.grid = new PF.Grid(this.matrix);
    this.findPath(map, this.grid);
  }

  public findPath(map, grid) {
    const entranceGridPoint = this.coordinatesToGrid(this.entranceDoor);
    const exitGridPoint = this.coordinatesToGrid(this.exitDoor);

    // Sort items coordinates by entrance distance
    const itemsGridPoint = this.itemsList.map((it) => it.location)
      .sort((a, b) => turf.distance(this.entranceDoor, a, { units: "miles" }) - turf.distance(this.entranceDoor, b, { units: "miles" }))
      .map((item) => this.coordinatesToGrid(item));

    // Find closest walkable point to item asset.
    const walkableItemGridPoints = [];
    itemsGridPoint.map((point) => {
      const node = grid.nodes[point[1]][point[0]];
      if (node.walkable === false) {
        if (grid.nodes[point[1]][point[0] + 2].walkable === true) {
          walkableItemGridPoints.push([point[0] + 2, point[1]]);
        }
        if (grid.nodes[point[1]][point[0] - 2].walkable === true) {
          walkableItemGridPoints.push([point[0] - 2, point[1]]);
        }
        if (grid.nodes[point[1] + 2][point[0]].walkable === true) {
          walkableItemGridPoints.push([point[0], point[1] + 2]);
        }
        if (grid.nodes[point[1] - 2][point[0]].walkable === true) {
          walkableItemGridPoints.push([point[0], point[1] - 2]);
        }
      } else {
        walkableItemGridPoints.push(point);
      }
    });

    // Array of entrance first, pickUp points, exit last.
    const pickUpPoints = [
      entranceGridPoint,
      ...walkableItemGridPoints,
      exitGridPoint,
    ];

    // Select the algorithm
    const finder = new PF.AStarFinder({
      allowDiagonal: true,
      dontCrossCorners: true,
    });

    // Find path using the array of grid points
    pickUpPoints.forEach((item, index, pickUpPoints) => {
      const lastIndex = pickUpPoints.length - 1;
      if (index === lastIndex) return;
      const path = finder.findPath(
        item[0],
        item[1],
        pickUpPoints[index + 1][0],
        pickUpPoints[index + 1][1],
        grid.clone()
      );
      this.paths = this.paths.concat(path);
    });

    // Display error message if path finder fails.
    if (this.paths.length === 0) {
      this.$notification.open({
        message: "Unable to find path",
        description:
          "Something went wrong finding the path. Please, try to relocate the items.",
        duration: 3,
      });
    }

    // Transform Path finder result into mapbox coordinates for layer
    this.coordinates = this.gridToCoordinates(this.paths);

    // Update mapbox route layer with result path
    const lineString = turf.lineString(this.coordinates);
    map.getSource("route").setData(lineString);

    // Update mapbox items layer with products marker
    const itemsPoints = this.itemsList.map((item) =>
      turf.point(item.location, { name: item.product, icon: item.image })
    );
    this.itemsMarker = turf.featureCollection(itemsPoints);
    // Load item images for markers into map and update layer
    Promise.all(
      this.itemsMarker.features.forEach(
        (marker) =>
          new Promise<void>((resolve) => {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const url = require(`@/assets/${marker.properties.icon}`);
            map.loadImage(url, function(error, res) {
              map.addImage(marker.properties.name, res);
              resolve();
            });
          })
      )
    ).then(map.getSource("items").setData(this.itemsMarker));

    this.calculateTripTime();
  }

  // Transform mapbox coordinates into Path finder grid point
  public coordinatesToGrid(coordinates) {
    const mapCoordinates = [
      coordinates[0] - this.SW.lng,
      coordinates[1] - this.SW.lat,
    ];
    const result = [
      Math.round((mapCoordinates[0] * 100) / this.mapWidth),
      Math.round((mapCoordinates[1] * 100) / this.mapHeight),
    ];
    return result;
  }

  // Transform Path finder grid points into mapbox coordinates
  public gridToCoordinates(points) {
    const coordinates = points.map((gridPoint) => {
      const point = [
        (gridPoint[0] / 100) * this.mapWidth,
        (gridPoint[1] / 100) * this.mapHeight,
      ];
      const result = [point[0] + this.SW.lng, point[1] + this.SW.lat];
      return result;
    });
    return coordinates;
  }

  public mapMeasures() {
    this.mapHeight = Math.abs(this.NE.lat - this.SW.lat);
    this.mapWidth = Math.abs(this.NE.lng - this.SW.lng);
  }

  public setNonWalkablePoints(floorPlanAssets) {
    // Remove ceilingLight assets from obstacles.
    const allAssetsCoordinates = floorPlanAssets.features
      .filter((it) => it.properties.subCategories[0] !== "ceilingLight")
      .map((asset) => {
        return asset.geometry.coordinates;
      });

    // Filter out invalid feature collections inside floor plans coordinates.
    const floorCoordinates = this.floorPlan.features
      .filter((it) => it.id !== "53d1a3f6-dc3b-4ba5-bd9d-f69187af6447")
      .filter((it) => it.id !== "2313384d-d57d-483d-bf19-13c4b400941f")
      .filter((it) => it.id !== "84285659-2a0f-4b43-96f8-e8ff1f092188")
      .filter((it) => it.id !== "50fa3761-4839-433c-9759-5a4aae8e7944")
      .filter((it) => it.id !== "e971a7a9-f93d-482c-a96b-ed6963ac5567")
      .map((floor) => {
        return floor.geometry.coordinates;
      });
    const allCoordinates = [...allAssetsCoordinates, ...floorCoordinates];

    allCoordinates.forEach((assetCoordinate) => {
      const poly1 = turf.polygon(assetCoordinate);
      const poly1buffered = turf.buffer(poly1, 0.00004274, {
        units: "miles",
      });
      const bbox = turf.bbox(poly1buffered);
      const startCoordinates = [bbox[0], bbox[1]];
      const endCoordinates = [bbox[2], bbox[3]];
      const startPoints = this.coordinatesToGrid(startCoordinates);
      const endPoints = this.coordinatesToGrid(endCoordinates);
      this.setGrid(startPoints[0], startPoints[1], endPoints[0], endPoints[1]);
    });
  }

  // Set the non walkable points on the Path finder grid.
  public setGrid(startPoint_x, startPoint_y, endPoint_x, endPoint_y) {
    for (let i = startPoint_x; i < endPoint_x; i++) {
      for (let j = startPoint_y; j < endPoint_y; j++) {
        try {
          this.matrix[j][i] = 1;
        } catch (error) {
          console.error(`Failed on set point on the matrix`, error);
        }
      }
    }
  }

  public calculateTripTime() {
    this.distance = 0;
    const pickUpPoints = this.coordinates;

    pickUpPoints.forEach((item, index, pickUpPoints) => {
      const lastIndex = pickUpPoints.length - 1;
      if (index === lastIndex) return;
      const distance = turf.distance(item, pickUpPoints[index + 1], {
        units: "miles",
      });
      this.distance = this.distance + distance;
    });
    const milesToFeet = this.distance * 5280
    const roundedDistance = Math.round(milesToFeet);
    store.dispatch('setTotalDistance', roundedDistance)
    // miles / miles per hour
    const time = this.distance / this.speed; 
    const formatTime = moment
      .duration(time, "hours")
      .add(this.delay, "seconds")
      .format("h:mm:ss", { trim: false });
    return store.dispatch("setTotalTripsTime", formatTime);
  }
}
