import { IProducts } from "./model/products-data.model";

export const fpFour = [
    {
        "product": "Water",
        "category": "Beverages",
        "location": [-73.9850546588267, 40.74176217778768],
        "image": "water.png"
    },
    {
        "product": "Snacks",
        "category": "Snacks & Candy",
        "location": [-73.98505961122962, 40.74180211224592],
        "image": "snacks.png"
    },
    {
        "product": "Pasta",
        "category": "Pantry",
        "location": [-73.98511833258414, 40.74176619803589],
        "image": "pasta.png"
    },
    {
        "product": "Soup",
        "category": "Pantry",
        "location": [-73.98509746174147, 40.741680700696804],
        "image": "soup.png"
    },
    {
        "product": "Rice",
        "category": "Pantry",
        "location": [-73.98514306632113, 40.741715535783214],
        "image": "rice.png"
    },
    {
        "product": "Ice Cream",
        "category": "Frozen",
        "location": [-73.98506488909644, 40.74163620277204],
        "image": "iceCream.png"
    },
    {
        "product": "Sliced Bread",
        "category": "Bread & Bakery",
        "location": [-73.98500522756623, 40.74156974373156],
        "image": "bread.png"
    },
    {
        "product": "Eggs",
        "category": "Eggs & Dairy",
        "location": [-73.98515132346577, 40.74152873706328],
        "image": "eggs.png"
    },
    {
        "product": "Bacon",
        "category": "Meat & Seafood",
        "location": [-73.98503034332634, 40.74169169151679],
        "image": "bacon.png"
    },
    {
        "product": "Apples",
        "category": "Fruits & Vegetables",
        "location": [-73.98520332370093, 40.74161101839164],
        "image": "apples.png"
    }
] as IProducts[]