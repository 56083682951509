<template>
  <div style="position: absolute; margin: 1rem 1rem 0 auto; right: 0">
    <a-drawer
      title="Asset Details"
      placement="right"
      :closable="true"
      v-model:visible="visible"
      @close="onClose"
      :mask="false"
    >
      <div v-if="!selectedAssetData.name">
        <h4>Select an asset to see its information.</h4>
      </div>
      <div
        style="display: flex; flex-direction: column; align-items: flex-start"
        v-else
      >
        <h4 class="rp-asset-title">Floor plan asset name:</h4>
        <span>{{ selectedAssetData.name }}</span>
        <h4 class="rp-asset-title">Floor plan asset ID:</h4>
        <span>{{ selectedAssetData.id }}</span>
        <h4 class="rp-asset-title">Asset map coordinates:</h4>
        <span>{{ selectedAssetData.location }}</span>
        <div>
          <h4 class="rp-asset-title">Assign item:</h4>
          <a-select
            style="width: 200px"
            @select="saveItem"
            placeholder="Product"
          >
            <a-select-option value="Water">
              Water
            </a-select-option>
            <a-select-option value="Snacks">
              Snacks
            </a-select-option>
            <a-select-option value="Pasta">
              Pasta
            </a-select-option>
            <a-select-option value="Soup">
              Soup
            </a-select-option>
            <a-select-option value="Rice">
              Rice
            </a-select-option>
            <a-select-option value="Ice Cream">
              Ice Cream
            </a-select-option>
            <a-select-option value="Sliced Bread">
              Sliced Bread
            </a-select-option>
            <a-select-option value="Eggs">
              Eggs
            </a-select-option>
            <a-select-option value="Bacon">
              Bacon
            </a-select-option>
            <a-select-option value="Apples">
              Apples
            </a-select-option>
          </a-select>
        </div>
        <h4 class="rp-asset-title">
          Items stored in this asset:
        </h4>
        <div
          v-for="item in selectedAssetData.assetItems"
          :key="item.name"
          style="margin: 0.2rem 0"
        >
          <a-avatar
            :src="require(`@/assets/${item.icon}`)"
            shape="square"
          /><span class="rp-item-name"> {{ item.name }}</span>
        </div>
        <span v-show="!selectedAssetData.assetItems[0]"
          >No items assigned.</span
        >
      </div>
    </a-drawer>
  </div>
</template>
<script lang="ts" src="./RightPanel.component.ts"></script>
<style>
.rp-asset-title {
  font-size: 15px;
  margin-top: 1rem;
}
.rp-item-name {
  margin-left: 0.5rem;
}
</style>
