import { FPConfig } from "./model/floor-plan-config.model";

export const floorPlanSource = [
    {
        "id": "72e1e909-2226-4199-b814-1fc3d2760530",
        "name": "West 25th Street 137, New York",
        "ne": {lng: -73.99325577243103, lat: 40.74524767657388},
        "sw": {lng: -73.99359507187155, lat: 40.744907300151425},
        "entranceDoor": [-73.993536063273, 40.74499264843331],
        "exitDoor": [-73.993318804342, 40.7449921404079],
        "center": [-73.993380830425, 40.74507164629847],
        "zoom": 20
    },
    {
        "id": "381b470f-bb45-4228-a340-75278a1c8466",
        "name": "Park Avenue South 345, New York",
        "ne": {lng: -73.98451685085031, lat: 40.74182505935431},
        "sw": {lng: -73.985241355176, lat: 40.74149455300085},
        "entranceDoor": [-73.98499168200331, 40.74179138996092],
        "exitDoor": [-73.98493115453677, 40.74154072754669],
        "center": [-73.98486914582082, 40.7416626803508],
        "zoom": 19.5
    },
    {
        "id": "af39133f-a9e2-435c-bfcb-9e0c8abcab16",
        "name": "West 39th Street 323, New York",
        "ne": {lng: -73.99228200806041, lat: 40.75627159246767},
        "sw": {lng: -73.99256981835197, lat: 40.756052500171876},
        "entranceDoor": [-73.99246882396629, 40.75622382322524],
        "exitDoor": [-73.99252524806475, 40.75613798216895],
        "center": [-73.9924147706332, 40.75616186682598],
        "zoom": 20.5
    },
    {
        "id": "0fd75f69-682e-4e48-bf2f-3a7d3fc6be99",
        "name": "Dark Retail 1",
        "ne": {lng: 0.0002061375450068681, lat: 0.00027505783842229903},
        "sw": {lng: -0.0002536076022749967, lat: -0.00013934067041532217},
        "entranceDoor": [-0.000099156567330283, 0.00011203150134520001],
        "exitDoor": [-0.0002130658475891778, 0.00003504704837098416],
        "center": [-0.0000053644177171463525, 0.00005766749123381487],
        "zoom": 20
    },
    {
        "id": "9d19d39f-6190-463f-ab2b-21b5b8b3f8d9",
        "name": "Dark Retail 2",
        "ne": {lng: 0.00021265715196250312, lat: 0.00008890347582735103},
        "sw": {lng: -0.00009886074181508775, lat: -0.00008937769328554168},
        "entranceDoor": [0.000045044404402005966, 0.00002797493789330474],
        "exitDoor": [0.00005296874743976332, -0.0000605632241956755],
        "center": [0.00006353633392564006, 0.000004504410782146806],
        "zoom": 20.5
    }
] as FPConfig[]