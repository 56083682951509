import { createStore } from 'vuex';
import { fpOne } from "../72e1e909-2226-4199-b814-1fc3d2760530";
import { fpTwo } from '@/0fd75f69-682e-4e48-bf2f-3a7d3fc6be99';
import { fpFour } from '@/381b470f-bb45-4228-a340-75278a1c8466';
import { fpThree } from '@/9d19d39f-6190-463f-ab2b-21b5b8b3f8d9';
import { fpFive } from '@/af39133f-a9e2-435c-bfcb-9e0c8abcab16';

export default createStore({
  state: {
    itemsList: [],
    travelSpeed: 3,
    itemDelay: 5,
    tripsTime: null,
    totalDistance: 0,
    selectedAssetData: {},
    visibleLeftDrawer: false
  },
  mutations: {
    setItemsSelected(state, payload) {
      state.itemsList = payload
    },
    setTravelSpeed(state, payload) {
      state.travelSpeed = payload;
    },
    setTotalTripsTime(state, payload) {
      state.tripsTime = payload;
    },
    setTotalDistance(state, payload) {
      state.totalDistance = payload;
    },
    setItemDelay(state, payload) {
      state.itemDelay = payload;
    },
    setItemsLocation(state, payload) {
      const modifiedItemsList = state.itemsList.map(item => item.product === payload.name ? Object.assign({}, item, {location: payload.position}): item);
      state.itemsList = modifiedItemsList;
      localStorage.setItem(payload.floorPlanSelected, JSON.stringify(modifiedItemsList))
    },
    setLocalStorageState(state, payload) {
      state.itemsList = JSON.parse(localStorage.getItem(payload));
    },
    setSelectedAssetData(state, payload) {
      state.selectedAssetData = payload;
    },
    setLeftDrawerVisible(state, payload) {
      state.visibleLeftDrawer = payload;
    }
  },
  getters: {
    getItemsList: state => state.itemsList,
    getTravelSpeed: state => state.travelSpeed,
    getItemDelay: state => state.itemDelay,
    getSelectedAsset: state => state.selectedAssetData
  },
  actions: {
    setItemsList(context, payload) {
      context.commit('setItemsSelected', payload)
    },
    setTravelSpeed(context, payload) {
      context.commit('setTravelSpeed', payload)
    },
    setItemDelay(context, payload) {
      context.commit('setItemDelay', payload)
    },
    setTotalTripsTime(context, payload) {
      context.commit('setTotalTripsTime', payload)
    },
    setTotalDistance(context, payload) {
      context.commit('setTotalDistance', payload)
    },
    saveItemsLocation(context, payload) {
      context.commit('setItemsLocation', payload)
    },
    saveSelectedAsset(context, payload) {
      context.commit('setSelectedAssetData', payload)
    },
    setLeftDrawerVisible(context, payload) {
      context.commit('setLeftDrawerVisible', payload)
    },
    loadLocalStorage(context, payload) {
      if(!JSON.parse(localStorage.getItem(payload))) {
        if(payload === "72e1e909-2226-4199-b814-1fc3d2760530") {
          localStorage.setItem(payload, JSON.stringify(fpOne))
        }
        if(payload === "0fd75f69-682e-4e48-bf2f-3a7d3fc6be99") {
          localStorage.setItem(payload, JSON.stringify(fpTwo))
        }
        if(payload === "9d19d39f-6190-463f-ab2b-21b5b8b3f8d9") {
          localStorage.setItem(payload, JSON.stringify(fpThree))
        }
        if(payload === "381b470f-bb45-4228-a340-75278a1c8466") {
          localStorage.setItem(payload, JSON.stringify(fpFour))
        }
        if(payload === "af39133f-a9e2-435c-bfcb-9e0c8abcab16") {
          localStorage.setItem(payload, JSON.stringify(fpFive))
        }
        context.commit('setLocalStorageState', payload)
      } else {
        context.commit('setLocalStorageState', payload)
      }
    }
  },
  modules: {
  }
})
