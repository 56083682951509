import { IProducts } from "./model/products-data.model";

export const fpThree = [
    {
        "product": "Water",
        "category": "Beverages",
        "location": [0.00011583299587414331, 0.000010706967017881652],
        "image": "water.png"
    },
    {
        "product": "Snacks",
        "category": "Snacks & Candy",
        "location": [-0.00005032692092754587, 0.000006859804557279858],
        "image": "snacks.png"
    },
    {
        "product": "Pasta",
        "category": "Pantry",
        "location": [-0.00007094625433978763, -0.0000025564828263213712],
        "image": "pasta.png"
    },
    {
        "product": "Soup",
        "category": "Pantry",
        "location": [-0.00005638550300091083, -0.00005145584015053828],
        "image": "soup.png"
    },
    {
        "product": "Rice",
        "category": "Pantry",
        "location": [0.000148608686629359, 0.000008105097521138305],
        "image": "rice.png"
    },
    {
        "product": "Ice Cream",
        "category": "Frozen",
        "location": [0.00009724033671432153, -0.000008395038463504534],
        "image": "iceCream.png"
    },
    {
        "product": "Sliced Bread",
        "category": "Bread & Bakery",
        "location": [0.00018036366572005136, -0.000025829145073430482],
        "image": "bread.png"
    },
    {
        "product": "Eggs",
        "category": "Eggs & Dairy",
        "location": [-0.0000449110825684329, -0.00006800620869285012],
        "image": "eggs.png"
    },
    {
        "product": "Bacon",
        "category": "Meat & Seafood",
        "location": [-0.000002071805056402809, -0.000013998858548802673],
        "image": "bacon.png"
    },
    {
        "product": "Apples",
        "category": "Fruits & Vegetables",
        "location": [0.00015078794936584927, -0.00006598985410732894],
        "image": "apples.png"
    }
] as IProducts[]