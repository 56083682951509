<template>
  <a-layout style="height: 100vh">
    <LeftPanel />
    <a-layout>
      <a-layout-content>
        <FloorPlan />
      </a-layout-content>
      <RightPanel />
    </a-layout>
  </a-layout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import FloorPlan from "./components/FloorPlan.vue";
import LeftPanel from "./components/LeftPanel.vue";
import RightPanel from "./components/RightPanel.vue";

@Options({
  components: {
    FloorPlan,
    LeftPanel,
    RightPanel,
  },
})
export default class App extends Vue {}
</script>

<style>
#app {
  font-family: "Poppins", sans-serif;
  background: #ffffff;
}
</style>
