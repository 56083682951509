import { IProducts } from "./model/products-data.model";

export const fpFive = [
    {
        "product": "Water",
        "category": "Beverages",
        "location": [-73.99248059964229, 40.75616529749564],
        "image": "water.png"
    },
    {
        "product": "Snacks",
        "category": "Snacks & Candy",
        "location": [-73.99235218256365, 40.756164740167094],
        "image": "snacks.png"
    },
    {
        "product": "Pasta",
        "category": "Pantry",
        "location": [-73.99239224841102, 40.75612146044605],
        "image": "pasta.png"
    },
    {
        "product": "Soup",
        "category": "Pantry",
        "location": [-73.99248138899625, 40.75613492924168],
        "image": "soup.png"
    },
    {
        "product": "Rice",
        "category": "Pantry",
        "location": [-73.9924798461946, 40.75610011608816],
        "image": "rice.png"
    },
    {
        "product": "Ice Cream",
        "category": "Frozen",
        "location": [-73.99239831156372, 40.75615791598082],
        "image": "iceCream.png"
    },
    {
        "product": "Sliced Bread",
        "category": "Bread & Bakery",
        "location": [-73.99234193416177, 40.756101352392875],
        "image": "bread.png"
    },
    {
        "product": "Eggs",
        "category": "Eggs & Dairy",
        "location": [-73.99232398111614, 40.75607477183826],
        "image": "eggs.png"
    },
    {
        "product": "Bacon",
        "category": "Meat & Seafood",
        "location": [-73.99242401659764, 40.75615755681309],
        "image": "bacon.png"
    },
    {
        "product": "Apples",
        "category": "Fruits & Vegetables",
        "location": [-73.99250962383498, 40.75611981098615],
        "image": "apples.png"
    }
] as IProducts[]