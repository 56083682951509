<template>
  <div
    style="position: absolute; margin: 1rem auto 0 1rem; left: 0; z-index: 11"
  >
    <a-button class="drawer-btn" type="primary" @click="showPanel">
      Panel
    </a-button>
    <a-drawer
      placement="left"
      :closable="true"
      v-model:visible="panelVisible"
      @close="onPanelClose"
      :mask="false"
    >
      <img
        alt="Archilogic logo"
        class="logo"
        src="@/assets/archilogic-logo.png"
      />
      <div>
        <h3 class="items-title">Items</h3>
        <a-list item-layout="horizontal" :data-source="products">
          <template #renderItem="{ item }">
            <a-list-item>
              <a-list-item-meta>
                <template #title>
                  <span>{{ item.product }}</span>
                </template>
                <template #avatar>
                  <a-avatar
                    :src="require(`../assets/${item.image}`)"
                    shape="square"
                  />
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
      </div>
      <div class="slider">
        <h4 class="slider-title">Walk Speed</h4>
        <a-slider
          :default-value="travelSpeed"
          @afterChange="onChangeSpeed"
          :max="20"
          :min="1"
          :marks="{ 3: '3mph', 20: '20' }"
          tooltipPlacement="bottom"
          :tip-formatter="(value) => `${value} mph`"
        />
      </div>
      <div class="slider">
        <h4 class="slider-title">Item Delay</h4>
        <a-slider
          :default-value="itemDelay"
          @afterChange="onChangeDelay"
          :max="30"
          :min="1"
          :marks="{ 5: '5 s.', 30: '30' }"
          tooltipPlacement="bottom"
          :tip-formatter="(value) => `${value} seconds`"
        />
      </div>
      <div class="time-statistic">
        <a-statistic
          title="Total trips time"
          :value="tripsTime === null ? '0:00:00' : tripsTime"
          format="HH:mm:ss"
        />
      </div>
      <div>
        <a-statistic
          title="Total distance"
          :value="distance"
          suffix="ft"
        />
      </div>
      <div>
        <a-button
          type="primary"
          block
          size="large"
          style="margin: 1rem auto"
          @click="sortItemsList"
        >
          Generate New Order
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script lang="ts" src="./LeftPanel.component.ts"></script>
<style>
.logo {
  height: 23px;
  width: auto;
  margin-bottom: 2rem;
}
.items-title {
  font-size: 16px;
  text-align: center;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-list-item {
  padding: 5px 0 !important;
}
.ant-list-item-meta {
  align-items: center !important;
}
.ant-list-item-meta-avatar {
  margin-right: 12px !important;
}
.ant-list-item-meta-title {
  margin-bottom: 0px !important;
  vertical-align: middle !important;
  font-size: 13px !important;
  line-height: 1.5715;
}
.slider {
  text-align: center;
  margin-top: 2rem;
}
.slider-title {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.time-statistic {
  margin: 3rem 0 1rem 0;
}
.ant-statistic {
  margin: 1rem 0 !important;
  text-align: center;
}
.ant-statistic-content-suffix {
  font-size: 16px;
}
</style>
