/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
declare const window: Window &
   typeof globalThis & {
     URL: any
   }
export default function svgToImage(url: string, targetWidth = 2048) {
    const img = new Image();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const DOMURL = window.URL || window.webkitURL || window;
  
    const resultPromise = new Promise<string>((resolve, reject) => {
      img.onload = function () {
        const width = targetWidth;
        const height = (width / img.width) * img.height;
  
        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(img, 0, 0, width, height);
        DOMURL.revokeObjectURL(url);
        const dataUrl = canvas.toDataURL();
        resolve(dataUrl);
      };
      img.onerror = function () {
        reject({ error: "generating image failed" });
      };
    });
    img.crossOrigin = "anonymous";
    img.src = url;
  
    return resultPromise;
}
  