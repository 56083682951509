import { IProducts } from "./model/products-data.model";

export const fpOne = [
    {
        "product": "Water",
        "category": "Beverages",
        "location": [-73.99344818223103, 40.74507003911458],
        "image": "water.png"
    },
    {
        "product": "Snacks",
        "category": "Snacks & Candy",
        "location": [-73.99350373809101, 40.74510893634087],
        "image": "snacks.png"
    },
    {
        "product": "Pasta",
        "category": "Pantry",
        "location": [-73.99334933111436, 40.74510080856402],
        "image": "pasta.png"
    },
    {
        "product": "Soup",
        "category": "Pantry",
        "location": [-73.99337576804126, 40.74502649741609],
        "image": "soup.png"
    },
    {
        "product": "Rice",
        "category": "Pantry",
        "location": [-73.99340765251468, 40.745185443700706],
        "image": "rice.png"
    },
    {
        "product": "Ice Cream",
        "category": "Frozen",
        "location": [-73.99343949615837, 40.74514941642616],
        "image": "iceCream.png"
    },
    {
        "product": "Sliced Bread",
        "category": "Bread & Bakery",
        "location": [-73.99331109832362, 40.74516791011129],
        "image": "bread.png"
    },
    {
        "product": "Eggs",
        "category": "Eggs & Dairy",
        "location": [-73.99350589199662, 40.74521932252969],
        "image": "eggs.png"
    },
    {
        "product": "Bacon",
        "category": "Meat & Seafood",
        "location": [-73.9934155741285, 40.74512981311398],
        "image": "bacon.png"
    },
    {
        "product": "Apples",
        "category": "Fruits & Vegetables",
        "location": [-73.99344193718184, 40.74521932252969],
        "image": "apples.png"
    }
] as IProducts[]