import { IProducts } from "./model/products-data.model";

export const fpTwo = [
    {
        "product": "Water",
        "category": "Beverages",
        "location": [-0.000027904989281068993, 0.000012049217929188671],
        "image": "water.png"
    },
    {
        "product": "Snacks",
        "category": "Snacks & Candy",
        "location": [0.000003188719290392328, -0.000009671448495396362],
        "image": "snacks.png"
    },
    {
        "product": "Pasta",
        "category": "Pantry",
        "location": [0.0000437037987524036, 0.000007728850476951266],
        "image": "pasta.png"
    },
    {
        "product": "Soup",
        "category": "Pantry",
        "location": [0.00006302526657009366, 0.00003363236132258862],
        "image": "soup.png"
    },
    {
        "product": "Rice",
        "category": "Pantry",
        "location": [0.000049278945652986295, 0.00007520660211923769],
        "image": "rice.png"
    },
    {
        "product": "Ice Cream",
        "category": "Frozen",
        "location": [-0.00003524770573903879, 0.00003579313022328279],
        "image": "iceCream.png"
    },
    {
        "product": "Sliced Bread",
        "category": "Bread & Bakery",
        "location": [0.00004417355725649941, -0.00001800837080168094],
        "image": "bread.png"
    },
    {
        "product": "Eggs",
        "category": "Eggs & Dairy",
        "location": [-0.00015767184453352456, -0.000019852304802725484],
        "image": "eggs.png"
    },
    {
        "product": "Bacon",
        "category": "Meat & Seafood",
        "location": [-0.00011145313288807301, -0.00006533738627467756],
        "image": "bacon.png"
    },
    {
        "product": "Apples",
        "category": "Fruits & Vegetables",
        "location": [-0.00016830948450774486, 0.00005314391484034786],
        "image": "apples.png"
    }
] as IProducts[]